<template>
  <div id="app">
    <!-- <TabbarView></TabbarView> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <div style="height: 5rem;"></div> -->
    <div
      v-if="!$util.flag"
      style="
        text-align: center;
        font-size: 3vw;
        color: #888888;
        position: absolute;
        width: 100%;
        bottom: 2px;
        z-index: 999;
      "
    >
      <div>本服务由江西省监狱管理局提供</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showTabbar: false, // 是否显示底部导航栏
      active: 0, // 当前激活的导航项索引
    };
  },
  created() {
    // 清空本地存储
    localStorage.clear();
    // 在这里设置需要显示底部导航栏的页面路径
    // 在这里设置需要显示底部导航栏的页面路径
    const showTabbarRoutes = ['/', '/search'];
    // 监听路由变化，根据当前页面路径判断是否显示底部导航栏
    this.$router.afterEach((to) => {
      this.showTabbar = showTabbarRoutes.includes(to.path);
    });
  },
  methods: {},
};
</script>

<style>
@import '@/static/css/common.css';
</style>
