import { getdicCode } from '@/api/admin/index';
import util from '@/libs/util';
import axios from 'axios';
const state = {
  dict: {},
};
const mutations = {
  SET_DICT: (state, params) => {
    state.dict[params.key] = params.value;
  },
};
const actions = {
  getDict({ commit, state }, key) {
    return new Promise((resolve) => {
      if (state.dict[key]) {
        resolve(state.dict[key]);
      } else {
        let params = {
          dictCodes: key,
        };
        if (util.flag) {
          getdicCode(params).then((res) => {
            if (res.status === 200) {
              commit('SET_DICT', { key: key, value: res.data.data });
              resolve(res.data.data);
            }
          });
        } else {
          let data = {
            app_id: 'jxjybmfwxyhdk',
            interface_id: 'jyglgftzdxcxnew',
            version: '1.0',
            charset: 'UTF-8',
            timestamp: '20240822',
            biz_content: JSON.stringify(params),
            origin: '0',
            header: `{"authorization":"${localStorage.getItem('token')}"}`,
          };
          axios({
            url: util.gftSignUrl,
            method: 'post',
            params: data,
          }).then((res) => {
            if (res.data.success) {
              data.sign = res.data.data.sign;
              axios({
                url: util.gftApiUrl,
                method: 'post',
                params: data,
              }).then((res) => {
                if (res.data.code == 200) {
                  let objectdata = JSON.parse(res.data.data);
                  if (objectdata.code == 0) {
                    commit('SET_DICT', { key: key, value: objectdata.data });
                    resolve(objectdata.data);
                  }
                }
              });
            }
          });
        }
      }
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
