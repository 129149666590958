const user = {
  namespaced: true,
  state: {
    isVerifyVersion: true,
  },
  mutations: {
    setVerifyVersion(state, info) {
      state.isVerifyVersion = info;
    },
  },
};

export default user;
