import Util from '../../libs/util';

// 登录
export function sfbLogin(params) {
  return Util.http({
    url: `/api/system/loginByMobile`,
    method: 'get',
    params,
  });
}
// 根据字典编码查询字段项
export function getCscpBasicHxItemCode(params) {
  return Util.http({
    url: `/api/dic/cscpHxDicItems/getCscpBasicHxItemCode`,
    method: 'get',
    params,
  });
}
// 根据字典编码查询字段项
export function getdicCode(params) {
  return Util.http({
    url: `/api/sys/dic/item`,
    method: 'get',
    params,
  });
}
// 获取当前用户信息
export function cscpCurrentUserDetails(params) {
  return Util.http({
    url: `/api/system/cscpCurrentUserDetails`,
    method: 'get',
    params,
  });
}
// 文件下载
export function download(data) {
  return Util.http({
    url: `/api/mobile/sys/file/download`,
    method: 'POST',
    responseType: 'blob',
    data,
  });
}
// 文件下载-base64
export function downloadBase(data) {
  return Util.http({
    url: `/api/mobile/sys/file/downloadBase64`,
    method: 'POST',
    // responseType:'blob',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

// 文件在线地址
export function downloadToTemp(data) {
  return Util.http({
    url: `/api/mobile/sys/file/downloadToTemp`,
    method: 'POST',
    data,
  });
}

// 文件上传
export function uploadFile(data) {
  return Util.http({
    url: `/api/mobile/sys/file/upload`,
    method: 'POST',
    data,
  });
}
// 人脸识别
export function faceLiveness(params) {
  return Util.http({
    url: `/api/mobile/meeting/ability/getSimilarScore`,
    method: 'get',
    params,
  });
}
// 获取会议信息
export function meetingJoin(params) {
  return Util.http({
    url: `/api/mobile/meeting/tianyi/meetingJoin`,
    method: 'get',
    params,
  });
}
//须知
export function articleInfo(params) {
  return Util.http({
    url: `/api/mobile/article/info`,
    method: 'get',
    params,
  });
}
//公告
export function announceList(params) {
  return Util.http({
    url: `api/mobile/message/list`,
    method: 'get',
    params,
  });
}
//公告状态
export function announceUpdate(data) {
  return Util.http({
    url: `api/mobile/message/update`,
    method: 'put',
    data,
  });
}

//办事记录
export function recordList(params) {
  return Util.http({
    url: `api/mobile/affairs/record/list`,
    method: 'get',
    params,
  });
}
